import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';

// firebase init goes here
const config = {
	apiKey: 'AIzaSyC2e8JmECIRRNsDqyUs6OcY55dEhbrSH-E',
	authDomain: 'qimjo-prod.firebaseapp.com',
	databaseURL: 'https://qimjo-prod.firebaseio.com',
	projectId: 'qimjo-prod',
	storageBucket: 'qimjo-prod.appspot.com',
	messagingSenderId: '956530470444',
	appId: '1:956530470444:web:3ee7412aee21d75d5c6b89'
};

firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();

const usersCollection = db.collection('users');
const organizationsCollection = db.collection('organizations');

export { firebase, db, auth, usersCollection, organizationsCollection };
