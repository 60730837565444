<template>
	<nav class="navbar navbar-light navbar-expand-lg navbar-app">
		<router-link class="navbar-brand" to="/app">
			<img
				src="../assets/img/navbar-logo.png"
				class="d-inline-block align-top"
				alt=""
				loading="lazy"
			/>
		</router-link>
		<button
			class="navbar-toggler navbar-right ml-auto"
			type="button"
			data-toggle="collapse"
			data-target="#navbarNav"
			aria-controls="navbarNav"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			Menu <span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNav">
			<ul class="navbar-nav">
				<li class="nav-item dropdown">
					<a
						class="nav-link dropdown-toggle"
						href="#"
						id="navbarDropdownMenuLink"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Kategorien
					</a>
					<div
						class="dropdown-menu categories-dropdown"
						aria-labelledby="navbarDropdownMenuLink"
					>
						<router-link
							v-for="category in categories"
							:key="category.id"
							data-toggle="collapse"
							data-target=".navbar-collapse"
							:to="{
								name: 'SearchCategory',
								params: { categoryID: category.name }
							}"
							exact
							class="dropdown-item"
							>{{ category.name }}</router-link
						>
					</div>
				</li>
				<form class="form-inline" autocomplete="off" @submit.prevent="search">
					<div class="input-group">
						<input
							type="text"
							class="form-control"
							id="searchField"
							placeholder="Übung suchen"
							v-model="searchTerm"
						/>
						<div class="input-group-append">
							<div class="input-group-text">
								<font-awesome-icon :icon="searchIcon" />
							</div>
						</div>
					</div>
					<div
						class="searchResultsWrapper"
						v-if="searchResults.length > 0"
						v-click-outside="closeSearch"
					>
						<a
							v-for="searchResult in searchResults"
							:key="searchResult.item.id"
							@click="openVideo(searchResult.item.id)"
						>
							<div class="result_card">
								<img :src="searchResult.item.pictureUri" />
								<h2>{{ searchResult.item.name }}</h2>
							</div>
						</a>
					</div>
				</form>
			</ul>
			<ul class="nav navbar-nav navbar-right ml-auto appBar">
				<router-link
					to="/app"
					exact
					data-toggle="collapse"
					data-target=".navbar-collapse"
					class="nav-item"
					><span class="nav-link">Home</span></router-link
				>
				<router-link
					:to="{ name: 'Settings' }"
					data-toggle="collapse"
					data-target=".navbar-collapse"
					exact
					class="nav-item"
					><span class="nav-link">Einstellungen</span></router-link
				>
				<router-link
					to="/login"
					class="nav-item"
					v-if="!isLoggedIn"
					data-toggle="collapse"
					data-target=".navbar-collapse"
					><span class="nav-link">Anmelden</span></router-link
				>
				<router-link
					to="/logout"
					class="nav-item"
					data-toggle="collapse"
					data-target=".navbar-collapse"
					v-else
					><span class="nav-link" @click="logout">Abmelden</span></router-link
				>
			</ul>
		</div>
	</nav>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '@/store/pinia';
import { mapStores } from 'pinia';
const { db } =
	process.env.NODE_ENV === 'development'
		? require('../firebaseConfig_dev.js')
		: require('../firebaseConfig_prod.js');
export default {
	components: {
		FontAwesomeIcon
	},
	computed: {
		...mapStores(useStore)
	},
	data() {
		return {
			categories: [],
			searchIcon: faSearch,
			searchTerm: '',
			searchResults: [],
			isLoggedIn: false
		};
	},
	watch: {
		searchTerm: function(val) {
			if (val !== '') {
				if (this.timeout) clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					this.getSearchResults(val);
				}, 300);
			} else {
				this.searchResults = [];
			}
		}
	},
	mounted() {
		this.isLoggedIn = this.appStore.getIsLoggedIn;
		this.getCategories();
	},
	methods: {
		async getCategories() {
			const categorySnapshot = await db.collection('categories').get();
			categorySnapshot.forEach(category => {
				let data = category.data();
				data.id = category.id;
				this.categories.push(data);
			});
		},
		async getSearchResults(value) {
			const searchResponse = await this.$api.post(
				'/api/searchVideos',
				{
					searchTerm: value
				},
				{
					headers: {
						Authorization: `Bearer ${this.appStore.getAccessToken}`
					}
				}
			);
			const data = searchResponse.data.slice(0, 6);
			this.searchResults = data;
		},
		search() {
			const term = this.searchTerm;
			this.$router.push({ name: 'Search', params: { searchTerm: term } });
			this.searchTerm = '';
			this.searchResults = [];
		},
		openVideo(id) {
			this.searchResults = [];
			this.searchTerm = '';
			this.$router.push('/app/video/' + id);
		},
		closeSearch() {
			this.searchResults = [];
			this.searchTerm = '';
		},
		logout() {
			this.$emit('logout');
		}
	}
};
</script>

<style></style>
