<template>
	<div class="page">
		<NavigationGuest v-if="!$route.path.includes('app')" />
		<NavigationApp v-else v-on:logout="unsubscribeOnMessage" />
		<router-view />
		<FootComponent />
	</div>
</template>

<script>
const fb =
	process.env.NODE_ENV === 'development'
		? require('./firebaseConfig_dev.js')
		: require('./firebaseConfig_prod.js');
import NavigationApp from '@/components/NavigationApp.vue';
import NavigationGuest from '@/components/NavigationGuest.vue';
import Swal from 'sweetalert2';
import { useStore } from '@/store/pinia';
import { mapStores } from 'pinia';

export default {
	components: {
		NavigationGuest,
		NavigationApp,
		FootComponent: () => import('@/components/FootComponent.vue')
	},
	data() {
		return {
			unsubscribe: null
		};
	},
	computed: {
		...mapStores(useStore)
	},
	mounted() {
		if (fb.firebase.messaging.isSupported()) {
			const audio = new Audio('/notification.mp3');
			this.unsubscribe = this.$messaging.onMessage(payload => {
				if (this.appStore.isLoggedIn) {
					try {
						audio.play();
					} catch {
						console.debug('Audio not supported');
					}
					Swal.fire({
						title: payload.notification.title,
						text: payload.notification.body,
						icon: 'info',
						showCancelButton: true,
						confirmButtonColor: '#c2d32b',
						cancelButtonColor: '#00a8d7',
						confirmButtonText:
							payload.data.type === '0'
								? 'Lass uns trainieren!'
								: 'Wir haben noch eine Übung für Dich!',
						cancelButtonText:
							payload.data.type === '0' ? 'Jetzt nicht!' : 'Schließen'
					}).then(result => {
						if (result.value) {
							window.location.href = payload.fcmOptions.link;
						}
					});
				}
			});
		}
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf('MSIE');
		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
			Swal.fire({
				title: '<strong>Internet Explorer ist nicht unterstützt</strong>',
				icon: 'info',
				html:
					'<h2>Ihr Browser (Internet Explorer) wird nicht unterstützt.</h2><p>Bitte verwenden Sie einen der anderen Browser, wie beispielsweise: <br /><ul><li>Chrome</li><li>FireFox</li><li>Safari</li><li>Edge</li><li>Opera</li></ul><br />Vielen dank für ihr Verständnis!</p>'
			});
		}
		const channel = new BroadcastChannel('sw-messages');
		channel.addEventListener('message', function(event) {
			window.location.href = event.data.data.clickAction;
		});
	},
	methods: {
		unsubscribeOnMessage() {
			this.unsubscribe();
		}
	}
};
</script>
