<template>
	<nav class="navbar sticky-top navbar-light navbar-expand-lg">
		<div class="container">
			<div class="navbar-header">
				<a class="navbar-brand" href="/">
					<img
						src="../assets/img/logo.png"
						height="30"
						class="img-responsive"
						alt=""
					/>
				</a>
			</div>
			<button
				class="navbar-toggler navbar-right ml-auto"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNav"
				aria-controls="navbarNav"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				Menu <span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse ml-auto" id="navbarNav">
				<ul class="nav navbar-nav navbar-right ml-auto">
					<router-link to="/" exact class="nav-item"
						><span class="nav-link">Home</span></router-link
					>
					<router-link
						:to="{ name: 'Home', hash: '#services' }"
						exact
						class="nav-item"
						data-toggle="collapse"
						data-target=".navbar-collapse"
						><span class="nav-link">Was ist qimjo?</span></router-link
					>
					<router-link
						:to="{ name: 'Home', hash: '#testimonials' }"
						exact
						data-toggle="collapse"
						data-target=".navbar-collapse"
						class="nav-item"
						><span class="nav-link">Referenzen</span></router-link
					>
					<router-link
						:to="{ name: 'Home', hash: '#order' }"
						exact
						data-toggle="collapse"
						data-target=".navbar-collapse"
						class="nav-item"
						><span class="nav-link">Preise</span></router-link
					>
					<router-link
						:to="{ name: 'Home', hash: '#contact' }"
						exact
						data-toggle="collapse"
						data-target=".navbar-collapse"
						class="nav-item"
						><span class="nav-link">Kontakt</span></router-link
					>
					<router-link
						:to="{ name: 'App' }"
						exact
						data-toggle="collapse"
						data-target=".navbar-collapse"
						class="nav-item"
						v-if="loggedIn"
						><span class="nav-link nav-link-highlight" rel="nofollow"
							>Mein qimjo</span
						></router-link
					>
					<router-link
						to="/login"
						data-toggle="collapse"
						data-target=".navbar-collapse"
						class="nav-item"
						v-if="!loggedIn"
						><span class="nav-link">Anmelden</span></router-link
					>
					<router-link
						to="/logout"
						data-toggle="collapse"
						data-target=".navbar-collapse"
						class="nav-item"
						v-else
						><span class="nav-link" rel="nofollow">Abmelden</span></router-link
					>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
import { useStore } from '@/store/pinia';
import { mapStores } from 'pinia';
export default {
	name: 'NavigationGuest',
	computed: {
		...mapStores(useStore)
	},
	data() {
		return {
			loggedIn: false
		};
	},
	mounted() {
		this.loggedIn = this.appStore.getIsLoggedIn;
		this.appStore.$subscribe((mutation, state) => {
			this.loggedIn = state.isLoggedIn;
		});
	}
};
</script>
