import axios from 'axios';
import router from '../router';
import { useStore } from '@/store/pinia';
const { auth } = process.env.NODE_ENV === 'development' ? require('../firebaseConfig_dev.js') : require('../firebaseConfig_prod.js');
let axiosPlugin = {};

axiosPlugin.install = function(Vue) {
	//Todo make "baseURL" depending on environment
	Vue.prototype.$api = axios.create({
		baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : '/'
	});
	Vue.prototype.$api.interceptors.response.use(
		function(res) {
			return res;
		},
		async function(error) {
			const store = useStore();
			if (error.response.status === 401) {
				const token = await refreshToken();
				store.updateAccessToken(token);
				error.config.headers['Authorization'] = 'Bearer ' + token;
				return axios.request(error.config);
			} else if (error.response.status === 403) {
				await Vue.prototype.$api.post('/authentication/logout', {
					token: store.getCurrentUser.refreshToken
				});
				await auth.signOut();
				store.logout();
				router.replace('/');
			} else {
				return Promise.reject(error);
			}
		}
	);
	Vue.prototype.$api.interceptors.request.use(
		function(config) {
			const store = useStore();
			const token = store.getAccessToken;
			config.headers['Authorization'] = 'Bearer ' + token;
			return config;
		},
		function(error) {
			// Do something with request error
			return Promise.reject(error);
		}
	);
	async function refreshToken() {
		const store = useStore();
		const refreshingCall = await Vue.prototype.$api.post('/authentication/token', {
			refreshToken: store.getCurrentUser.refreshToken,
			firebaseToken: store.getCurrentUser.authToken
		});
		return refreshingCall.data.accessToken;
	}
};
export default axiosPlugin;
