import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},

	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
	},
	{
		path: '/app',
		name: 'App',
		component: () => import(/* webpackChunkName: "main" */ '../views/App.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/processLogin',
		name: 'ProcessLogin',
		component: () => import(/* webpackChunkName: "ProcessLogin" */ '../views/ProcessLogin.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/order',
		name: 'ProcessOrder',
		component: () => import(/* webpackChunkName: "ProcessLogin" */ '../views/ProcessOrder.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Logout.vue')
	},
	{
		path: '/app/onboarding',
		name: 'Onboarding',
		component: () => import(/* webpackChunkName: "main" */ '../views/Onboarding.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/activate',
		name: 'Activate',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
	},
	{
		path: '/invite',
		name: 'Invite',
		component: () => import(/* webpackChunkName: "auth" */ '../views/Invite.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/app/search/category/:categoryID',
		name: 'SearchCategory',
		component: () => import(/* webpackChunkName: "main" */ '../views/SearchView.vue'),
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/app/search/videos/:searchTerm',
		name: 'Search',
		component: () => import(/* webpackChunkName: "main" */ '../views/SearchView.vue'),
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/app/video/:videoID',
		name: 'Video',
		component: () => import(/* webpackChunkName: "main" */ '../views/VideoView.vue'),
		props: true,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/app/search/suggested',
		name: 'SuggestedSearch',
		component: () => import(/* webpackChunkName: "main" */ '../views/SearchView.vue'),
		props: { suggested: true },
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/app/settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "main" */ '../views/SettingsView.vue'),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/datenschutz',
		name: 'Datenschutz',
		component: () => import(/* webpackChunkName: "main" */ '../views/Privacy.vue')
	},
	{
		path: '/impressum',
		name: 'Impressum',
		component: () => import(/* webpackChunkName: "main" */ '../views/Impressum.vue')
	},
	{
		path: '/agb',
		name: 'AGB',
		component: () => import(/* webpackChunkName: "main" */ '../views/AGB.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return setTimeout(() => {
				return document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth' });
			}, 500);
		} else {
			return savedPosition || { x: 0, y: 0 };
		}
	}
});

export default router;
