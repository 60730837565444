import { defineStore } from 'pinia';
export const useStore = defineStore('app', {
	state: () => ({
		currentUser: {
			authToken: null,
			accessToken: null,
			refreshToken: null,
			onboarded: false
		},
		userObject: null,
		isLoggedIn: false,
		orderDetails: null,
		activationCode: null,
		temporaryVideoPath: '',
		showSubtitlesInfo: true
	}),
	getters: {
		getIsLoggedIn: state => {
			return state.isLoggedIn;
		},
		getCurrentUser: state => {
			return state.currentUser;
		},
		getUserObject: state => {
			return state.userObject;
		},
		getAuthToken: state => {
			return state.currentUser.authToken;
		},
		getAccessToken: state => {
			return state.currentUser.accessToken;
		},
		getRefreshToken: state => {
			return state.currentUser.refreshToken;
		},
		getMsgToken: state => {
			return state.currentUser.msgToken;
		},
		getOrderDetails: state => {
			return state.orderDetails;
		},
		getActivationCode: state => {
			return state.activationCode;
		},
		getTemporaryVideoPath: state => {
			return state.temporaryVideoPath;
		},
		getShowSubtitlesInfo: state => {
			return state.showSubtitlesInfo;
		}
	},
	actions: {
		setCurrentUser(data) {
			this.currentUser = Object.assign({}, data);
			this.isLoggedIn = data !== null && data !== undefined;
		},
		setOnboarded(val) {
			this.currentUser.onboarded = val;
		},
		setUserObject(val) {
			this.userObject = val;
			this.isLoggedIn = val !== null && val !== undefined;
		},
		setUserToken({ authToken, accessToken, refreshToken }) {
			this.currentUser.authToken = authToken;
			this.currentUser.accessToken = accessToken;
			this.currentUser.refreshToken = refreshToken;
		},
		setMsgToken(msgToken) {
			this.currentUser.msgToken = msgToken;
		},
		setLoggedIn(status) {
			this.isLoggedIn = status;
		},
		setOrderDetails(orderObject) {
			this.orderDetails = orderObject;
		},
		setLikes(likes) {
			this.currentUser.likes = likes;
		},
		setDislikes(dislikes) {
			this.currentUser.dislikes = dislikes;
		},
		setActivationCode(value) {
			this.activationCode = value;
		},
		setTemporaryVideoPath(path) {
			this.temporaryVideoPath = path;
		},
		setShowSubtitlesInfo(value) {
			this.showSubtitlesInfo = value;
		},
		updateAccessToken(token) {
			this.currentUser.accessToken = token;
		},
		logout() {
			this.currentUser = {
				authToken: null,
				accessToken: null,
				refreshToken: null,
				onboarded: false
			};
			this.isLoggedIn = false;
			this.userObject = null;
		}
	},
	persist: true
});
