import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia, PiniaVuePlugin } from 'pinia';
import titlePlugin from './plugins/titlePlugin';
import clickOutside from './plugins/clickOutside';
import axiosPlugin from './plugins/axios.plugin';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { useStore } from '@/store/pinia';

import 'bootstrap';
import './assets/scss/app.scss';

const fb = process.env.NODE_ENV === 'development' ? require('./firebaseConfig_dev.js') : require('./firebaseConfig_prod.js');

Vue.config.productionTip = false;

if (fb.firebase.messaging.isSupported()) {
	Vue.prototype.$messaging = fb.firebase.messaging();
}

Vue.use(titlePlugin);
Vue.use(clickOutside);
Vue.use(axiosPlugin);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
	const isVideo = to.name === 'Video';
	const isApp = to.name === 'App';
	const store = useStore(pinia);
	if (requiresAuth) {
		fb.firebase.auth().onAuthStateChanged(user => {
			if (user) {
				if (isApp && store.getTemporaryVideoPath !== '') {
					next(store.getTemporaryVideoPath);
					store.setTemporaryVideoPath('');
				}
				next();
			} else {
				if (isVideo) {
					store.setTemporaryVideoPath(to.fullPath);
				}
				next('/login');
			}
		});
	} else {
		next();
	}
});

new Vue({
	pinia,
	router,
	render: h => h(App)
}).$mount('#app');
