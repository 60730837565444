var titlePlugin = {};

titlePlugin.install = function(Vue) {
	Vue.prototype.$setTitle = function(
		name,
		tags = [],
		seperator = '|',
		basename = 'qimjo'
	) {
		if (tags.length > 0) {
			Array.from(
				document.querySelectorAll('[data-vue-router-controlled]')
			).map(el => el.parentNode.removeChild(el));
			tags
				.map(tag => {
					const tagElement = document.createElement('meta');
					Object.keys(tag).forEach(key => {
						tagElement.setAttribute(key, tag[key]);
					});
					tagElement.setAttribute('data-vue-router-controlled', '');

					return tagElement;
				})
				.forEach(tag => document.head.appendChild(tag));
		}
		document.title = `${name} ${seperator} ${basename}`;
	};
};

module.exports = titlePlugin;
