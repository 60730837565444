<template>
	<div class="home">
		<Header></Header>
		<Services></Services>
		<Videos> </Videos>
		<Testimonials></Testimonials>
		<Order> </Order>
		<Contact></Contact>
	</div>
</template>

<script>
export default {
	name: 'Home',
	components: {
		Header: () => import('@/components/home/Header.vue'),
		Services: () => import('@/components/home/Services.vue'),
		Videos: () => import('@/components/home/Videos.vue'),
		Order: () => import('@/components/home/Order.vue'),
		Contact: () => import('@/components/home/Contact.vue'),
		Testimonials: () => import('@/components/home/Testimonials.vue')
	},
	mounted() {
		this.$setTitle(
			'Ihr Onlineportal für die Betriebliche Gesundheitsförderung - qimjo',
			[
				{ 'http-equiv': 'content-Type', content: 'text/html; utf-8' },
				{ name: 'robots', content: 'INDEX,FOLLOW' },
				{ 'http-equiv': 'content-Language', content: 'de' },
				{
					name: 'title',
					content:
						'qimjo - Ihr Onlineportal für die Betriebliche Gesundheitsförderung'
				},
				{
					name: 'description',
					content:
						'feel good - work better ✅ qimjo ist ein wachsendes Onlineportal gefüllt mit Übungsvideos, die während des Büroalltags absolviert werden können.'
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: 'https://qimjo.de'
				},
				{
					property: 'og:title',
					content:
						'qimjo - Ihr Onlineportal für die Betriebliche Gesundheitsförderung'
				},
				{
					name: 'og:description',
					content:
						'feel good - work better ✅ qimjo ist ein wachsendes Onlineportal gefüllt mit Übungsvideos, die während des Büroalltags absolviert werden können.'
				},
				{
					property: 'twitter:url',
					content: 'https://qimjo.de'
				},
				{
					property: 'twitter:title',
					content:
						'qimjo - Ihr Onlineportal für die Betriebliche Gesundheitsförderung'
				},
				{
					name: 'twitter:description',
					content:
						'feel good - work better ✅ qimjo ist ein wachsendes Onlineportal gefüllt mit Übungsvideos, die während des Büroalltags absolviert werden können.'
				}
			]
		);
		if ('gtag' in window) {
			setTimeout(function() {
				window.gtag('event', 'Seitenansichtsdauer', {
					event_category: 'mindestens 30 Sekunden'
				});
			}, 30000);
		}
	}
};
</script>
